*,
*::before,
*::after {
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

html {
    scroll-behavior: smooth;
}

@import "~react-image-gallery/styles/css/image-gallery.css";

@font-face {
    font-family: "Poppins";
    src: url("../fonts/Poppins-Regular.eot");
    src: url("../fonts/Poppins-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-Regular.woff2") format("woff2"),
    url("../fonts/Poppins-Regular.woff") format("woff"),
    url("../fonts/Poppins-Regular.ttf") format("truetype"),
    url("../fonts/Poppins-Regular.svg#Poppins-Regular") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Poetsen One";
    src: url("../fonts/PoetsenOne-Regular.eot");
    src: url("../fonts/PoetsenOne-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/PoetsenOne-Regular.woff2") format("woff2"),
    url("../fonts/PoetsenOne-Regular.woff") format("woff"),
    url("../fonts/PoetsenOne-Regular.ttf") format("truetype"),
    url("../fonts/PoetsenOne-Regular.svg#PoetsenOne-Regular") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/* Start Body */
body {
    margin: 0;
    font-size: 1rem;
    font-family: "Poppins";
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
    scroll-behavior: smooth;
}

h6,
.h6,
h2,
.h2,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    font-family: "Poetsen One";
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
    font-family: "Poppins";
}

img {
    width: 100%;
    max-width: 100%;
    display: block;
    object-fit: contain;
}

.mainWrapper {
    float: left;
    width: 100%;
}

:root {
    --primaryDark: #2b2730;
    --darkGrey: #2b2730;
    --darkColor: #000000;
    --lightColor: #ffffff;
    --lightGrey: #5d5d5d;
    --greyColor: #616161;
    --boderColor: #dadada;
}

.displaySection {
    float: left;
    width: 100%;
}

.container {
    width: 1170px;
    margin: 0 auto;
}

a {
    text-decoration: none;
    font-family: "Poppins";
}

.rowFlex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

/* Header */
header {
    background: var(--primaryDark);
    padding: 12px 0;
    width: 100%;
}

header nav ul li a {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    text-transform: capitalize;
    color: var(--lightColor);
    text-decoration: none;
    margin: 8px 18px;
    position: relative;
    cursor: pointer;
}

.heroBanner .leftText a:hover {
    background: #000 !important;
    color: #fff !important;
}
.heroBanner .rowFlex,
.contactSection .rowFlex {
    flex-wrap: nowrap;
}

header nav ul li a.active span {
    opacity: 1;
}

header nav ul li a span {
    width: 100%;
    height: 3px;
    display: block;
    position: absolute;
    border-radius: 0;
    left: 0;
    right: 0;
    border: 0;
    margin: 0 auto;
    opacity: 0;
}

header nav ul li a:hover span {
    opacity: 1;
}

header nav ul li:last-child a {
    padding-right: 0;
}

header nav ul li {
    display: inline-block;
}

/* Header End */

/* Banner */
.heroBanner {
    padding: 16px 0 0;
}

.heroBanner .leftText h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 69px;
    line-height: 100px;
    text-transform: capitalize;
}

.heroBanner .leftText p {
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 37px;
    text-transform: capitalize;
    color: var(--darkColor);
}

.newgalleryFull .owl-nav .fa-chevron-right,
.new-templte-revivew .owl-nav .fa-chevron-right {
    background: #c50000;
    color: #fff;
}

.heroBanner .leftText a {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
    text-transform: capitalize;
    color: var(--lightColor);
    border-radius: 40px;
    padding: 10px 30px;
    margin-top: 35px;
    margin-bottom: 25px;
    display: inline-block;
}

.heroBanner .leftText a:hover {
    background: var(--darkColor);
}

.heroBanner .leftText {
    max-width: 35rem;
}

.heroBanner .leftImg {
    max-width: 27rem;
}

.header-overly-banner {
    float: left;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.header-overly-banner header {
    background: transparent;
}

.header-overly-banner .heroBanner {
    padding: 50px 0 67px 0;
}

.newgalleryFull h2 {
    width: 100%;
    padding: 26px;
    margin-bottom: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 69px;
    text-transform: capitalize;
    text-align: center;
    line-height: 88px;
}

.newgalleryFull {
    position: relative;
    margin-top: 45px;
}

.newgalleryFull img {
    max-height: 727px;
    object-fit: contain;
    margin-left: auto;
    margin-right: auto;
}

.newgalleryFull .owl-nav {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.newgalleryFull .owl-nav i {
    background: #000;
    color: #fff;
    width: 51px;
    height: 51px;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

#gallery.newgalleryFull button {
    margin: 0 !important;
    padding: 0 !important;
}

/* General Section */
.generalFlex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.generalFlex .leftBox, .generalFlex .rightBox {
    width: 50%;
}

.generalFlex .leftBox {
    margin-top: 0;
    padding-right: 45px;
    text-align: center;
}

.generalFlex .leftBox .headTitle p {
    font-size: 23px;
    line-height: 33px;
    padding-right: 0;
    margin-top: 25px;
}

.generalFlex .leftBox .headTitle h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 69px;
    line-height: 76px;
    text-transform: capitalize;
    margin-bottom: 20px;
    padding-right: 0;
}

.generalFlex .rightBox img {
    object-fit: contain;
}

.generalFlex .leftBox {
    padding-left: 45px;
    text-align: right;
}
.generalFlex:nth-child(even) .leftBox {
    order: 2;
    text-align: left;
}

.generalFlex:not(:first-child) {
    padding-top: 130px;
}

#generalSection {
    margin: 130px 0;
    background: #f3f3f3;
    padding: 130px 0;
}

/* General Section */
footer {
    background: var(--primaryDark);
    padding: 20px 0;
}

.socialLink a {
    margin: 0 7px;
    padding: 0;
    line-height: 0px;
}

.socialLink {
    display: flex;
    color: var(--lightColor);
    justify-content: center;
    align-items: center;
}

.socialLink span {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: #ffffff;
    margin-right: 5px;
}

.socialLink a {
    color: white;
    font-size: 1.5em;
}

.copyWrite {
    color: var(--lightColor);
    text-align: center;
}

.copyWrite p {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: right;
    color: var(--lightColor);
    margin-bottom: 0;
    margin-top: 10px;
}

footer .rowFlex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.new-templte-revivew .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.new-templte-revivew .head h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 80px;
    line-height: 110px;
    text-transform: capitalize;
}

.new-templte-revivew .head p {
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 33px;
    text-transform: capitalize;
    margin: 7px 0;
    padding-bottom: 15px;
}

.new-templte-revivew .head {
    padding: 24px 43px 8px;
}

.new-templte-revivew .head h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 69px;
    line-height: 76px;
    text-transform: capitalize;
    margin-bottom: 0;
}

.leftreviewText p {
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 32px;
    margin-top: 17px;
}

.leftreviewText span {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 39px;
    text-transform: capitalize;
}

.new-templte-revivew .item .leftreviewText {
    width: 67%;
    padding-left: 67px;
}

.new-templte-revivew .leftreviewText .owl-nav {
    position: relative;
    margin-top: 0;
    text-align: start;
}

.new-templte-revivew .owl-nav i {
    background: #ffdcdc;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    color: #000;
    width: 51px;
    height: 51px;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.contactSection {
    text-align: center;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 130px 0 0px;
    padding: 67px 0 81px;
}

.contactSection .location {
    padding: 25px 0;
}

.contactSection h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 69px;
    line-height: 76px;
    text-transform: capitalize;
    color: #ffffff;
    text-align: left;
}

.address {
    flex-basis: 450px;
}

.location h3 {
    text-align: left;
}

.address p {
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 32px;
    text-transform: capitalize;
    color: #ffffff;
    margin-bottom: 35px;
    text-align: left;
}

.calling {
    display: flex;
    align-items: self-start;
    justify-content: flex-start;
    margin-bottom: 50px;
    margin-top: 23px;
}

.calling .iconCall {
    border-radius: 100%;
    width: 76px;
    height: 76px;
    padding: 23px 27px;
    margin-right: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.numberCall h4 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 39px;
    text-align: left;
}

.numberCall a {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 30px;
}

.numberCall p {
    margin: 0;
}

.numberCall p.endDay {
    margin-top: 13px;
}

.is-sticky {
    position: fixed;
    left: 0;
    width: 100%;
    z-index: 9999999999999999999;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    background: var(--primaryDark) !important;
    top: 0px;
    width: 100%;
    z-index: 9;
}

/* Mobile Menu */
.mobileMenu {
    display: none;
}

div#sidebarMenu ul li a {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    text-transform: capitalize;
    color: var(--lightColor);
    text-decoration: none;
    padding: 8px 20px;
    position: relative;
}

div#sidebarMenu ul li a.active:before {
    opacity: 1;
}

div#sidebarMenu ul li a::before {
    position: absolute;
    background: var(--primaryColor);
    width: 7px;
    height: 7px;
    border-radius: 100%;
    content: "";
    left: 0;
    margin: 0 auto;
    bottom: 17px;
    opacity: 0;
}

div#sidebarMenu ul li:hover a:before {
    opacity: 1;
}

div#sidebarMenu ul {
    padding: 0;
    margin-left: 19px;
}

/* div#sidebarMenu ul li a:hover {
  color: var(--primaryColor);
} */
div#sidebarMenu ul li {
    padding-bottom: 16px;
}

.main {
    margin: 0 auto;
    display: block;
    height: 100%;
    margin-top: 60px;
}

.mainInner {
    display: table;
    height: 100%;
    width: 100%;
    text-align: center;
}

.mainInner div {
    display: table-cell;
    vertical-align: middle;
    font-size: 3em;
    font-weight: bold;
    letter-spacing: 1.25px;
}

#sidebarMenu {
    height: 100vh;
    position: fixed;
    left: 0;
    width: 250px;
    margin-top: 0;
    transform: translateX(-250px);
    transition: transform 250ms ease-in-out;
    background: var(--primaryDark);
    z-index: 999;
    top: 51px;
}

.sidebarMenuInner {
    margin: 0;
    padding: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.sidebarMenuInner li {
    list-style: none;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    padding: 20px;
    cursor: pointer;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.sidebarMenuInner li span {
    display: block;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
}

.sidebarMenuInner li a {
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
}

input[type="checkbox"]:checked ~ #sidebarMenu {
    transform: translateX(0);
}

input[type="checkbox"] {
    transition: all 0.3s;
    box-sizing: border-box;
    display: none;
}

.sidebarIconToggle {
    cursor: pointer;
    height: 100%;
    height: 22px;
    left: 15px;
    position: fixed;
    top: 17px;
    width: 100%;
    width: 22px;
    z-index: 9999999999999999999999999999999999;
    display: none;
}

.spinner {
    transition: all 0.3s;
    box-sizing: border-box;
    position: absolute;
    height: 2px;
    width: 90%;
    background-color: var(--primaryColor);
    border-radius: 4px;
    margin: 2px 0;
}

.horizontal {
    transition: all 0.3s;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 3px;
}

.diagonal.part-1 {
    position: relative;
    transition: all 0.3s;
    box-sizing: border-box;
    float: left;
}

.diagonal.part-2 {
    transition: all 0.3s;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 3px;
}

input[type="checkbox"]:checked ~ .sidebarIconToggle > .horizontal {
    transition: all 0.3s;
    box-sizing: border-box;
    opacity: 0;
}

input[type="checkbox"]:checked ~ .sidebarIconToggle > .diagonal.part-1 {
    transition: all 0.3s;
    box-sizing: border-box;
    transform: rotate(135deg);
    margin-top: 8px;
}

input[type="checkbox"]:checked ~ .sidebarIconToggle > .diagonal.part-2 {
    transition: all 0.3s;
    box-sizing: border-box;
    transform: rotate(-135deg);
    margin-top: -11px;
}

/* Media */
@media screen and (max-width: 1920px) and (min-width: 1600px) {

    header nav ul li a {
        font-size: 20px;
        margin: 8px 24px;
    }

    .heroBanner {
        padding: 57px 0;
    }

    .gallerSlide h3 {
        font-size: 64px;
    }

    .headTitle h3 {
        font-size: 36px;
        line-height: 42px;
        margin-bottom: 0.4em;
    }

    .headTitle h2 {
        font-size: 62px;
        line-height: 75px;
    }

    .container {
        width: 90%;
        margin: 0 auto;
        padding-left: 15px;
        padding-right: 15px;
    }

    .review {
        padding-bottom: 0;
        overflow: hidden;
    }

    .review .imageReview {
        width: 45%;
    }

    .leftreviewText h2 {
        font-size: 36px;
        line-height: 42px;
        margin-bottom: 22px;
    }

    .leftreviewText h4 {
        font-size: 64px;
        line-height: 75px;
        padding-right: 35%;
    }

    .leftreviewText p {
        font-size: 28px;
        line-height: 33px;
        padding-right: 0;
        margin-top: 25px;
    }

    .leftreviewText span {
        font-size: 30px;
        line-height: 35px;
        margin-top: 36px;
    }

    .imageReview {
        width: 94%;
    }

    .numberCall a {
        font-size: 24px;
        line-height: 28px;
    }

    .calling .iconCall {
        width: 96px;
        height: 96px;
    }

    .numberCall h4 {
        font-size: 35px;
        line-height: 41px;
        margin-bottom: 16px;
    }

    .contactSection .rowFlex {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }

    .heroBanner .leftImg {
        max-width: 47rem;
    }

    .heroBanner .leftText a {
        font-size: 22px;
        line-height: 26px;
        padding: 22px 67px;
        margin-top: 35px;
    }

    .calling {
        margin-bottom: 47px;
        margin-top: 39px;
    }

    .contactSection h2 {
        font-size: 64px;
        line-height: 75px;
    }

    .address p {
        font-size: 30px;
        line-height: 35.16px;
        padding-right: 106px;
    }

    .map {
        width: 60%;
        padding-left: 0%;
    }

    .address {
        width: 37%;
    }
}

@media only screen and (max-width: 1200px) {
    .container {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }

    .header .rowFlex {
        justify-content: center;
    }

}

@media only screen and (max-width: 1024px) {
    .header nav {
        display: none;
    }

    .brand a img {
        max-height: 80px;
    }

    .newgalleryFull {
        margin-top: 65px;
    }

    #generalSection {
        margin: 65px 0;
        padding: 65px 0;
    }

    .generalFlex:not(:first-child) {
        padding-top: 65px;
    }

    .mobileMenu {
        display: block !important;
        z-index: 9999999999999999999;
        position: absolute;
    }

    .contactSection {
        margin: 0px 0 0px;
        padding: 65px 0 27px;
    }

    .new-templte-revivew .owl-nav {
        position: relative;
    }

    .sidebarIconToggle {
        display: block;
        z-index: 99;
    }

    .heroBanner .leftText h1 {
        font-size: 49px;
        line-height: 74px;
    }

    .heroBanner .leftText p {
        font-size: 20px;
        line-height: 25px;
    }

    .heroBanner .leftText a {
        margin-top: 19px;
    }

    .newgalleryFull h2 {
        font-size: 45px;
        line-height: 54px;
    }

    .gallerSlide h3 {
        font-size: 41px;
        line-height: 75px;
        margin-bottom: 34px;
        margin-left: 63px;
    }

    .gallerSlider .owl-theme .owl-nav {
        top: -89px;
    }

    .headTitle h2 {
        font-size: 41px;
        line-height: 43px;
    }

    .generalFlex .leftBox .headTitle p {
        font-size: 17px;
        line-height: 30px;
    }

    .leftreviewText h4 {
        font-size: 37px;
        line-height: 46px;
        padding-right: 5%;
    }

    .leftreviewText p {
        font-size: 17px;
        line-height: 25px;
        padding-right: 0%;
        margin-top: 9px;
    }

    .leftreviewText span {
        margin-top: 20px;
    }

    .review {
        overflow: hidden;
    }

    .new-templte-revivew .head h2, .generalFlex .leftBox .headTitle h2 {
        font-size: 45px;
        line-height: 54px;
    }

    .generalFlex .leftBox {
        padding-right: 39px;
    }

    .new-templte-revivew .item .leftreviewText {
        width: 67%;
        padding-left: 63px;
    }

    .contactSection h2 {
        font-size: 41px;
        line-height: 45px;
    }

    .new-templte-revivew .head p {
        font-size: 17px;
        line-height: 25px;
        margin: 7px 0;
    }

    .address p {
        font-size: 24px;
        line-height: 26px;
    }

    .calling .iconCall {
        width: 46px;
        height: 46px;
        padding: 9px 12px;
        margin-right: 13px;
    }

    .numberCall {
        text-align: left;
    }

    .numberCall h4 {
        font-size: 22px;
        line-height: 27px;
    }

    .numberCall a {
        font-size: 20px;
        line-height: 17px;
    }

    .copyWrite p {
        margin-bottom: 0;
    }

    .leftreviewText h2 {
        font-size: 27px;
        line-height: 34px;
    }

    .contactSection .rowFlex {
        display: block;
        align-items: flex-end;
        justify-content: space-between;
    }

    .map iframe {
        width: 100% !important;
        height: 250px !important;
        margin-bottom: 35px;
    }

    .contactSection h2 {
        font-style: normal;
        font-weight: 400;
        font-size: 45px;
        line-height: 45px;
        text-transform: capitalize;
        color: #ffffff;
        text-align: left;
    }

    .address p,
    .numberCall a {
        font-size: 17px;
        line-height: 23px;
    }

    .numberCall h4 {
        font-size: 20px;
    }

    .map {
        margin-top: 0;
    }

    .calling .iconCall {
        width: 45px;
        height: 45px;
        margin-top: 20px;
        padding: 13px;
    }

    .calling {
        display: flex;
        align-items: self-start;
        justify-content: flex-start;
        margin-bottom: 50px;
        margin-top: 23px;
    }

    .socialLink span {
        display: none;
    }

    .new-templte-revivew .head {
        padding: 14px 20px 6px;
    }
}

@media only screen and (min-width: 1024px) {
    .address {
        padding-right: 10px;
    }
}

@media only screen and (min-width: 821px) {
    .review .imageReview {
        width: 45%;
    }

    .review .leftreviewText {
        width: 50%;
    }
}

@media only screen and (min-width: 821px) and (max-width: 1024px) {
    .review {
        margin-bottom: 65px;
    }
}

@media only screen and (max-width: 820px) {
    .location h3 {
        text-align: center;
    }
    .footerBrand {
        display: none;
    }
    .generalFlex .leftBox,
    .generalFlex:nth-child(even) .leftBox {
        padding-left: 0;
        text-align: center;
    }

    .new-templte-revivew .head {
        padding: 14px 20px 6px;
    }

    .socialLink {
        display: block;
        position: relative;
        top: 0;
        right: 0;
        text-align: center;
    }

    .new-templte-revivew .owl-nav {
        position: relative;
        top: 0;
        left: 0;
    }

    .imageReview {
        margin: 0 auto;
        width: 100%;
    }

    .footerBrand img {
        height: 50px;
        object-fit: contain;
        object-position: left;
    }

    footer {
        background: var(--primaryDark);
        padding: 20px 0;
    }

    .socialLink span {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
        color: #ffffff;
        margin-right: 5px;
    }

    .socialLink a {
        margin: 0 7px;
        padding: 0;
        line-height: 0px;
    }

    .copyWrite p {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        color: var(--lightColor);
        margin-bottom: 0;
        margin-top: 10px;
    }

    .map iframe {
        width: 100% !important;
        height: 250px !important;
        margin-bottom: 35px;
    }

    .calling {
        margin-bottom: 0;
    }

    .calling .iconCall {
        border-radius: 100%;
        width: 47px;
        height: 47px;
        padding: 21px 13px;
        margin-right: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .numberCall {
        text-align: left;
        position: relative;
        top: 0;
    }

    .newgalleryFull h2 {
        font-size: 34px;
        font-style: normal;
        font-weight: 400;
        line-height: 73px;
        padding: 0;
        text-align: center;
        text-transform: capitalize;
    }

    .contactSection .rowFlex {
        display: block;
        align-items: flex-end;
        justify-content: space-between;
    }

    footer {
        margin-top: 0 !important;
    }

    .new-templte-revivew .head h2, .generalFlex .leftBox .headTitle h2 {
        font-size: 34px;
        line-height: 48px;
    }

    .generalFlex .leftBox .headTitle h2 {
        margin-bottom: 0;
    }

    .new-templte-revivew .head {
        width: 100%;
    }

    .new-templte-revivew .leftreviewText {
        width: 100%;
        padding-left: 0;
        text-align: center;
    }

    .new-templte-revivew .leftreviewText .owl-nav {
        text-align: center;
    }

    .generalFlex {
        display: block;
        text-align: center;
    }

    .generalFlex:not(:first-child) {
        padding-top: 65px;
    }

    .generalFlex .leftBox .headTitle h2 {
        padding-right: 0;
        text-align: center;
    }

    .generalFlex .leftBox, .generalFlex .rightBox {
        width: 100%;
    }

    .generalFlex .leftBox {
        padding-right: 0;
    }

    #generalSection {
        margin: 65px 0;
        padding: 65px 0;
    }

    .generalFlex .leftBox .headTitle {
        margin-bottom: 37px;
        padding: 0 101px;
    }

    .new-templte-revivew .head p {
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 32px;
        text-transform: capitalize;
        margin: 0;
        padding-bottom: 15px;
        max-width: 100%;
    }

    .review {
        margin-top: 0px !important;
        padding: 0 !important;
    }

    .contactSection {
        padding-bottom: 27px;
        margin-top: 60px !important;
    }

    .gallerSlider .owl-theme .owl-nav {
        top: -69px;
    }

    .flexCenterBut {
        text-align: center;
    }

    footer .rowFlex {
        display: block !important;
    }

    .brand a img {
        max-height: 80px;
        width: 100%;
    }

    #contact-us .rowFlex {
        display: block;
    }

    .heroBanner .leftText {
        max-width: initial;
        text-align: center;
    }

    .fooColumn {
        width: 100%;
    }

    .copyWrite {
        text-align: left;
    }

    .heroBanner .rowFlex {
        display: flex;
        flex-direction: column;
    }

    .header-overly-banner .heroBanner {
        padding: 0 0 65px;
    }

    .heroBanner {
        padding: 21px 0 56px;
    }

    .heroBanner .leftText p {
        font-size: 20px;
        line-height: 34px;
        max-width: 18em;
    }

    .gallerSlider {
        padding: 49px 0;
    }

    .gallerSlide h3 {
        font-size: 41px;
        line-height: 75px;
        margin-bottom: 13px;
        margin-left: 0;
    }

    .headTitle h3 {
        font-size: 29px;
        line-height: 27px;
    }

    .headTitle h2 {
        font-size: 28px;
        line-height: 32px;
    }

    .review {
        margin-top: 46px;
        padding: 77px 0 0;
    }

    .leftreviewText h2 {
        font-size: 29px;
        line-height: 34px;
    }

    .leftreviewText h4 {
        font-size: 28px;
        line-height: 38px;
        padding-right: 5%;
    }

    .leftreviewText p {
        font-size: 17px;
        line-height: 25px;
        padding-right: 0px;
        max-width: 100%;
        margin: 24px auto 0;
    }

    .contactSection {
        margin: 65px 0 0px !important;
        padding: 39px 0 7px;
    }

    .contactSection h2 {
        font-size: 34px;
        line-height: 74px;
    }

    .address {
        text-align: center;
    }

    .address p {
        font-size: 17px;
        line-height: 23px;
        margin-bottom: 28px;
    }

    .numberCall h4 {
        font-size: 20px;
        line-height: 31px;
        text-align: center;
    }
    
    .numberCall p {
        text-align: center;
    }

    .numberCall p.endDay {
        margin-top: 0 !important;
    }

    .contactInfo {
        display: flex;
    }

    .calling {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 33px;
        margin-top: 23px;
        width: 50%;
    }

    .map {
        width: 100%;
        padding-left: 0;
        margin-top: 43px;
    }

    .displaySection {
        float: left;
        width: 100%;
        position: relative;
        margin-top: 70px;
    }

    .contactSection.displaySection,
    .newgalleryFull.displaySection {
        margin-top: 65px;
    }

    header {
        background: var(--primaryDark);
        padding: 12px 0;
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 99999;
    }

    #reviewSlider .item {
        display: block;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    header .brand {
        text-align: center;
    }
}

/* Mobile query */
@media only screen and (max-width: 600px) {
    #orderonline {
        margin-top: 100px;
    }

    .heroBanner .leftText h1 {
        font-size: 33px;
        line-height: 51px;
    }

    .generalFlex .leftBox {
        padding-right: 0;
    }

    #generalSection {
        margin: 65px 0;
        padding: 65px 0;
    }

    .generalFlex .leftBox .headTitle {
        margin-bottom: 37px;
        padding: 0 0px;
    }

    .imageReview {
        width: 100%;
        margin: 0 auto;
    }

    .codeBox_border_dash h3 {
        font-size: 23px;
    }

    div#reviews {
        margin-top: 40px;
    }

    div#contact-us {
        margin-top: 17px !important;
    }

    footer {
        margin-top: 0px !important;
    }

    #gallery.newgalleryFull button {
        margin: 7px !important;
        padding: 0 !important;
    }

    .heroBanner .leftText p {
        font-size: 18px;
        line-height: 26px;
        max-width: 26em;
    }

    .couponCode-box .tittleInfo h4 {
        font-style: normal;
        font-weight: 400;
        font-size: 26px;
        line-height: 30px;
        text-align: center;
        font-family: "Poppins";
        text-transform: capitalize;
        margin-bottom: 15px;
        margin-top: 13px;
    }

    #reviewSlider .owl-nav {
        bottom: 0;
        position: unset;
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    .contactSection {
        padding-bottom: 0;
        margin-top: 65px !important;
    }

    .owl-carousel .owl-item img {
        display: block;
        width: 100%;
        margin-bottom: 20px;
    }

    .heroBanner {
        padding: 21px 0 0;
    }

    .leftreviewText p {
        max-width: 100%;
        font-size: 13.3846px;
        line-height: 16px;
    }

    .review {
        padding-top: 57px;
    }

    .gallerSlider {
        padding: 49px 0 0;
    }

    .displaySection {
        float: left;
        width: 100%;
        position: relative;
        margin-top: 70px;
    }

    .gallerSlider .owl-theme .owl-nav {
        margin-top: 12px;
        position: relative;
        top: -119px;
        right: 0;
        left: 0;
        top: inherit;
    }

    .gallerSlide h3 {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 27px;
        margin-left: 0;
        text-align: center;
        color: var(--primaryColor);
        position: relative;
        padding-bottom: 15px;
    }

    .gallerSlide h3::before {
        position: absolute;
        content: "";
        background: var(--primaryColor);
        width: 50px;
        height: 2px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    .headTitle h3 {
        font-size: 24px;
        line-height: 28px;
        padding-bottom: 15px;
        position: relative;
        margin-bottom: 13px;
    }

    .review {
        margin-top: 45px;
        padding-top: 44px;
    }

    .headTitle h3::before {
        position: absolute;
        content: "";
        background: var(--primaryColor);
        width: 50px;
        height: 2px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    .headTitle h2 {
        font-size: 20px;
        line-height: 24px;
    }

    .headTitle h2 br {
        display: none;
    }

    .leftreviewText h2 {
        font-size: 20px;
        line-height: 18px;
        position: relative;
        padding-bottom: 15px;
        margin-bottom: 19px;
    }

    .leftreviewText h2::before {
        position: absolute;
        content: "";
        background: var(--primaryColor);
        width: 50px;
        height: 2px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    .leftreviewText h4 {
        font-size: 22px;
        line-height: 26px;
        padding-right: 0;
        width: 14rem;
        margin: 0 auto;
    }

    .contactSection h2 {
        font-size: 24px;
        line-height: 28px;
    }

    .address p {
        font-size: 15px;
        line-height: 18px;
    }

    .socialLink span {
        display: none;
    }

    .copyWrite p {
        font-size: 10px;
        margin-top: 9px;
    }

    .calling.last-cal {
        margin-bottom: 0 !important;
    }

    .map {
        margin-top: 0px;
    }

    .iconCallMobile h4 {
        display: block;
        font-style: normal;
        font-weight: 400;
        font-size: 19px;
        line-height: 27px;
        color: var(--primaryColor);
        margin-bottom: 0;
    }

    .owl-carousel.owl-drag .owl-item .last {
        display: block;
    }

    .calling {
        display: block;
        align-items: center;
        justify-content: center;
        margin-bottom: 33px;
        margin-top: 23px;
    }

    .numberCall {
        text-align: center;
    }

    .numberCall a {
        margin-top: 15px;
        display: block;
    }

    .iconCallMobile {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.ReactModalPortal {
    z-index: 9999999;
    position: relative;
}

#gallerySlider button {
    border: 0;
    box-shadow: none;
    padding: 0;
    z-index: 99999999999999;
    position: relative;
    cursor: pointer;
}

button.close__model {
    position: absolute;
    top: 7px;
    right: 8px;
    background: none;
    border: 0;
    font-size: 25px;
    cursor: pointer;
}

.image-gallery-slides {
    height: 400px;
    object-fit: cover;
}

.stickyNav ul li {
    padding: 9px 0;
}

nav.stickyNav ul li a {
    font-size: 17px;
    color: #2b2730;
}

.templateToggle {
    position: fixed;
    right: 19px;
    top: 36%;
}

.sidebar {
    width: 280px;
    min-height: 100vh;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
    background-color: #fff;
    position: fixed;
    top: 0;
    right: -100%;
    z-index: 999999999999999;
    transition: 0.5s;
}

.sd-header .btn {
    cursor: pointer;
}

.templateToggle i {
    font-size: 28px;
    color: #dc4238;
    cursor: pointer;
}

.sidebar.active {
    right: 0;
}

.sd-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
}

.sidebar-overlay {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
}

.mainTemp:hover {
    color: #dc4238;
}

.sushiTemp:hover {
    color: #ffaa00;
}

.itaTemp:hover {
    color: #a020f0;
}

.chiTemp:hover {
    color: #dc4238;
}

.pizzaTemp:hover {
    color: #1f9d1f;
}

.pastaTemp:hover {
    color: #cb7847;
}

.seaTemp:hover {
    color: #e75e03;
}

.burTemp:hover {
    color: #0000ff;
}

.mexTemp:hover {
    color: #ffd809;
}

.griTemp:hover {
    color: #00c2ff;
}

.ramTemp:hover {
    color: #a301ef;
}

.mobileMenu label {
    background: transparent;
    box-shadow: none;
    top: 0;
    right: -19px;
    height: auto !important;
}

.mobileMenu label span {
    margin-top: 53px;
}

.mobileMenu label span:before, .mobileMenu label span:after {
    background: #fff;
}

.mobileMenu nav {
    white-space: nowrap;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 66px 0;
}

.mobileMenu nav ul li a {
    color: #fff;
    display: inline-block;
    font-size: 30px;
    padding: 6px 0;
    cursor: pointer;
}

.mobileMenu nav ul li {
    list-style: none;
}

.mobileMenu nav ul {
    padding: 0;
    margin-top: 0px;
}

.errorPage {
    background: #2b2730;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
}

.review .owl-carousel {
    max-width: 600px;
    margin: auto;
}

#reviews .reviewFlex {
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

p {
    white-space: pre-wrap;
}
.footerBrand h2, .brand h2 {
    font-family: "Poppins";
    text-transform: uppercase;
    margin-bottom: 0;
}

.hide {
    display: none !important;
}
